<template>
	<div>
		<v-expansion-panel-header ripple hide-actions class="rounded-xl">
			<v-row class="justify-content-between">
				<v-col cols="12" md="7" sm="12" align-self="center">
					<span class="title font-weight-bold">{{ course.name }}</span>
					<p class="subtitle-2">
						{{ course.shortDescription }}
					</p>
				</v-col>
				<v-col cols="12" md="2" sm="3" align-self="center">
					<v-chip :ripple="false" v-for="semester in course.semesters" :key="semester.key" class="mx-1">
						{{ semester.name }}
					</v-chip>
				</v-col>
				<v-col cols="12" md="3" sm="9" align-self="center">
					<CourseJoinButton :loading-parent="loading" :course="course" style="float: right" />
				</v-col>
			</v-row>
		</v-expansion-panel-header>
		<v-expansion-panel-content class="pt-5 rounded-xl">
			<v-row class="justify-content-between">
				<v-col cols="12" md="5" sm="12">
					<v-list>
						<v-list-item v-for="degree in course.degrees" :key="degree.id">
							<v-list-item-title style="white-space: normal"> [{{ degree.type }}] {{ degree.name }} </v-list-item-title>
						</v-list-item>
					</v-list>
					<v-btn text rounded color="info" :to="{ name: 'CourseDetails', params: { id: course.id } }">
						{{ $t('courses.details') }}
					</v-btn>
				</v-col>
				<v-col cols="12" md="7" sm="12">
					<p class="body-2 text-justify" style="white-space: pre-line">
						{{ course.longDescription }}
					</p>
				</v-col>
			</v-row>
		</v-expansion-panel-content>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CourseSearchCard',
	props: {
		course: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
		}
	},
	components: {
		CourseJoinButton: () => import('@/components/courses/CourseJoinButton.vue'),
	},
	computed: {
		...mapGetters({
			availableSemesters: 'data/availableSemesters',
		}),
	},
	created() {
		this.loading = false
	},
	methods: {},
}
</script>
